<template>
    <div class="container-fluid mt-5">
      <div class="row my-3 justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="h3 text-muted d-none d-lg-block">Wann und wo findet die nächste Schulung statt?</div>
				<div class="h3 text-muted d-block d-lg-none">Wann ist was?</div>
				<div class="h1 company-color1">Unsere Maßnahmenübersicht<br></div>
				Hier findest du alle Informationen über die nächsten Schulungstermine zu unseren Maßnahmen.<br>
        Für eine Anmeldung oder weitere Informationen ruf uns gerne an.<br>
				<small class="text-muted">Du vermisst etwas oder hast weitere Fragen? Kontaktiere uns gerne.</small>
        </div>
      </div>
    <fa-element client_id="fahrschule_werner" type="education_list" category="measure" hideEmpty/>
  </div>
</template>

<script>
export default {
  name: 'TheoryStandard',
}
</script>
